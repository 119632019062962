<template>
  <div style="background: white">
    <Head></Head>
    <div class="page">
      <h1>Política de privacidad</h1>
      <p style="margin-bottom: 35px">
        De conformidad con la legislación en protección de datos y el artículo
        10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y
        Comercio Electrónico el propietario del sitio y responsable del
        tratamiento de los datos es:
      </p>
      <p style="margin-bottom: 50px">
        ELOSUNG EUROPE, S.A. CIF A-05270319 <br />
        Datos de contacto de la empresa: <br />
        Calle Carrer de Francesc Teixidó, 43, 08918 Badalona, Barcelona <br />
        <a href="mailto:soporte@rakumart.es" class="youxiang"
          >soporte@rakumart.es
        </a>
      </p>

      <h1 style="margin-bottom: 36px; font-size: 14px">
        Uso y finalidad de los datos obtenidos
      </h1>
      <p style="margin-bottom: 36px">
        Los datos que solicitamos en nuestra página web son los adecuados,
        pertinentes y estrictamente necesarios para la finalidad de gestionar y
        tramitar la petición que nos ha realizado, poder enviarle información de
        nuestros servicios, así como realizar la compraventa de productos y
        servicios, y en ningún caso está obligado a facilitarlos.
      </p>
      <p style="margin-bottom: 18px">Usos de los datos obtenidos:</p>
      <ul>
        <li>
          Gestionar la petición realizada en nuestros respectivos formularios de
          contacto.
        </li>
        <li>
          Enviar comunicaciones comerciales vía SMS, WhatsApp y correo
          electrónico.
        </li>
        <li>
          Gestionar la compra de productos por parte del titular de los datos.
        </li>
      </ul>
      <p>
        Los datos de cumplimentación obligatoria se especifican en el propio
        formulario donde se recaban los datos, y su negativa a suministrarlos
        implicará no poder gestionar su petición. Asimismo, nos asegura que
        todos los datos facilitados son ciertos, veraces y pertinentes para la
        finalidad por la que los solicitamos.
      </p>
      <p>
        Los datos personales proporcionados se conservarán mientras se mantenga
        la relación comercial, y una vez finalice la citada relación se
        mantendrán bloqueados el tiempo legalmente establecido, antes de su
        destrucción.
      </p>
      <p style="margin-bottom: 10.6667px">
        La base de legitimación para el tratamiento de sus datos:
      </p>
      <ul style="margin-bottom: 10.6667px">
        <li>
          El consentimiento del interesado para la gestión y tramitación de
          peticiones, así como para el envío de comunicaciones comerciales.
        </li>
        <li>
          Relación contractual para la formalización y gestión de la compraventa
          de productos.
        </li>
      </ul>
      <p>
        El envío de los mismos implica su autorización expresa a incorporarlos a
        nuestros ficheros correspondientes, siempre y cuando Elosung Europe, S.A
        lo considere conveniente para la gestión de la petición que solicite.
      </p>
      <p style="margin-bottom: 10.6667px">
        Sus datos no serán cedidos a terceros sin su consentimiento expreso, a
        excepción de aquellas cesiones necesarias para dar cumplimiento a una
        obligación legal.
      </p>
      <p style="margin-bottom: 10.6667px">
        Le comunicamos que sus datos serán transferidos a un tercer país fuera
        del espacio económico europeo dado que la compra de productos se realiza
        a empresas residentes en la República Popular de China y los datos
        recabados en la página web son conservados en un servidor instalado en
        el mismo país. Dicha transferencia es necesaria para la realización de
        la compraventa y la ejecución del contrato.
      </p>
      <p style="margin-bottom: 18px">
        Mediante la aceptación de la casilla del formulario, usted consiente el
        envío de información de nuestros servicios que puedan resultar de su
        interés.
      </p>
      <p style="margin-bottom: 18px">
        Es importante que para que podamos mantener sus datos personales
        actualizados, nos informe siempre que haya alguna modificación en ellos.
        En caso contrario, no respondemos de la veracidad de los mismos.
        Consideramos que, si no cancela sus datos personales expresamente de
        nuestros ficheros, continúa interesado en seguir incorporado a los
        mismos hasta que el Responsable lo considere oportuno y mientras sea
        adecuado a la finalidad por la que se obtuvieron.
      </p>
      <h1 style="margin-bottom: 18px; font-size: 14px">
        Derechos de los interesados
      </h1>
      <p style="margin-bottom: 18px">
        El titular de los datos personales tiene derecho a:
      </p>
      <ul style="margin-bottom: 18px">
        <li>
          Obtener confirmación sobre si el Responsable está tratando los datos
          personales que les conciernan, o no.
        </li>
        <li>
          Acceder a sus datos personales, así como a solicitar la rectificación
          de los datos inexactos o, en su caso, solicitar su supresión cuando,
          entre otros motivos, los datos ya no sean necesarios para los fines
          que fueron recogidos.
        </li>
        <li>
          En determinadas circunstancias, los interesados podrán solicitar la
          limitación del tratamiento de sus datos, en cuyo caso únicamente el
          Responsable los conservará para el ejercicio o la defensa de
          reclamaciones.
        </li>
        <li>
          En determinadas circunstancias y por motivos relacionados con su
          situación particular, los interesados podrán oponerse al tratamiento
          de sus datos.
        </li>
        <li>
          En determinadas circunstancias, en virtud del derecho de portabilidad,
          los interesados tendrán derecho a obtener sus datos personales en un
          formato estructurado de uso común y lectura mecánica y transmitirlo a
          otro responsable.
        </li>
      </ul>
      <p style="margin-bottom: 10.6667px">
        El titular puede ejercer sus derechos:
      </p>
      <ul style="margin-bottom: 10.6667px">
        <li>
          Mediante escrito dirigido a Elosung Europe, S.A, calle Carrer de
          Francesc Teixidó, 43, 08918 Badalona, Barcelona, referencia
          “Protección de Datos”.
        </li>
        <li>
          Mediante correo electrónico a la dirección soporte@rakumart.es
          indicando en el asunto “Protección de Datos”.
        </li>
      </ul>
      <p style="margin-bottom: 32px">
        Si considera que sus derechos no se han atendido adecuadamente, tiene
        derecho a presentar una reclamación ante la Agencia Española de
        Protección de Datos.
      </p>
      <h2 style="margin-bottom: 18px">
        Consentimiento para el uso de cookies.
      </h2>
      <p>
        Para que nuestro sitio web funcione correctamente utilizamos cookies.
        Para obtener su consentimiento válido para el uso y el almacenamiento de
        cookies en el navegador que utiliza para acceder a nuestro sitio web y
        para documentarlo adecuadamente utilizamos una plataforma de gestión del
        consentimiento: CookieFirst. Esta tecnología es proporcionada por
        Digital Data Solutions BV, Plantage Middenlaan 42a, 1018 DH, Amsterdam,
        Países Bajos. Sitio web:
        <a href=" " title="Cookiefirst page">https://cookiefirst.com</a>
        denominado CookieFirst.
      </p>
      <p>
        Cuando usted accede a nuestro sitio web, se establece una conexión con
        el servidor de CookieFirstpara darnos la posibilidad de obtener su
        consentimiento válido para el uso de determinados cookies. A
        continuación, CookieFirst almacena un cookie en su navegador para poder
        activar sólo aquellos cookies a los que usted ha dado su consentimiento
        y para documentarlo adecuadamente. Los datos procesados se almacenan
        hasta que expira el periodo de almacenamiento predefinido o hasta que
        usted solicita la eliminación de los datos. A pesar de lo anterior,
        pueden aplicarse determinados periodos de almacenamiento legales
        obligatorios.
      </p>
      <p>
        CookieFirst se utiliza para obtener el consentimiento legalmente
        requerido para el uso de cookies. La base legal para ello es el artículo
        6(1)(c) del Reglamento General de Protección de Datos (GDPR).
      </p>
      <h2 style="margin-bottom: 18px">Acuerdo de procesamiento de datos</h2>
      <p>
        Hemos celebrado un acuerdo de procesamiento de datos con CookieFirst. Se
        trata de un contrato exigido por la ley de protección de datos, que
        garantiza que los datos de los visitantes de nuestro sitio web solo se
        procesan de acuerdo con nuestras instrucciones y en cumplimiento del
        GDPR.
      </p>
      <h2 style="margin-bottom: 18px">Archivos de registro del servidor</h2>
      <p>
        Nuestro sitio web y CookieFirst recogen y almacenan automáticamente
        información en los denominados archivos de registro del servidor
      </p>
    </div>
    <Foot style="width: 100%"></Foot>
  </div>
</template>
<script>
import Head from "../../components/head/HomePageTop.vue";
import Foot from "@/components/foot/Foot.vue";
export default {
  data() {
    return {};
  },
  components: { Head, Foot },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.page {
  width: 750px;
  margin: 30px auto;
  padding: 10px;
  h1 {
    font-size: 16px;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 20px;
  }
  p {
    line-height: 1.295;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 18px;
    .youxiang {
      color: #0563c1;
      text-decoration: underline;
    }
  }
  ul {
    margin-bottom: 35px;
    margin-top: 18px;
    li {
      margin-left: 40px;
      list-style: disc;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.295;
    }
  }
}
</style>
